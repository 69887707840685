import { Vue, Options } from "vue-class-component";
import { getInvite, acceptInvite } from "@/api/services/InviteService";
import { onPauseEvent } from "@/api/services/EventService";
import { Invite } from "@/models/Invite";
import { getContact } from "@/api/services/ContactService"
import { Contact } from "@/models";


@Options({})
export default class CommonPage extends Vue {
  invite: Invite | null = null;
  contact: Contact | null = null;

  get id(): string {
    return this.$route.query.id as string;
  }

  get showScreener(): boolean {
    return this.invite?.showScreener ?? true;
  }

  get hasAdditionalInfo(): boolean {
    return this.invite?.showAdditionalInfo ?? false;
  }

  async toErrorPage() {
    await this.$router.push({
      name: "SurveyExpiredV2",
      query: {
        id: this.id,
      },
    });
  }

  async toPausePage() {
    onPauseEvent(this.id)

    await this.$router.push({
      name: "SurveyPausedV2",
      query: {
        id: this.id,
      },
    });
  }

  async created() {
    if (!this.id) {
      this.toErrorPage();
    }

    const invite = await getInvite(this.id)

    if (invite) {
      if (!this.isProjectStatusValid(invite)) return false
      if (!this.isCampaignStatusValid(invite)) return false
      this.inviteValidation(invite);
      this.invite = invite;
    } else {
      this.toErrorPage();
    }

    if (this.showScreener) {
      const contact = await getContact(this.id)

      if (contact) {
        this.contact = contact
      }
    }
  }

  async onAcceptInvite(): Promise<void | boolean> {
    if (!this.id) return false;

    if (this.invite?.isDisapher || this.$route.name === "RedirectV2") {
      await acceptInvite(this.id)
    } else {
      await this.$router.push({
        name: "RedirectV2",
        query: {
          id: this.id
        }
      })
    }
  }

  isProjectStatusValid(invite: Invite) {
    const status = invite?.projectStatus

    if (!status) return true

    switch (status.toLowerCase()) {
      case "completed":
      case "canceled":
      case "closed":
        this.toErrorPage();
        return false;

      case "paused":
        this.toPausePage()
        return false;

      default:
        break;
    }

    return true
  }

  isCampaignStatusValid(invite: Invite) {
    switch (invite.campaignStatus) {
      case "STOPPED":
      case "COMPLETED":
        this.toErrorPage();
        return false;
      case "PAUSED":
        this.toPausePage()
        return false;
      default:
        break;
    }

    return true
  }

  inviteValidation(invite: Invite): void {
    const { isSurveyAvailable } = invite;

    if (!isSurveyAvailable) {
      this.toErrorPage();
    } else if (invite.isAlreadyCompleted) {
      this.$router.push({
        name: "completeV2",
        query: {
          id: this.id
        },
      });
    } else if (invite.isAlreadyTerminated) {
      this.$router.push({
        name: "terminatedV2",
        query: {
          id: this.id
        },
      });
    } else if (invite.isAlreadyOverQuota) {
      this.$router.push({
        name: "OverQuotaV2",
        query: {
          id: this.id
        },
      });
    }
  }
}
