import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c87f548"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "decline" }
const _hoisted_2 = { class: "decline__img" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "decline__message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: require('@/assets/images/rewards/decline-confirm-icon.svg'),
        alt: ""
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("decline.confirmMessage", { name: _ctx.invite?.topic })), 1)
  ]))
}