import axios from "axios"
import { Endpoint } from "@/api/endpoints"
import Common from "./Common"

export default class EventService extends Common {
  static async startScreener(id: string): Promise<void> {
    try {
      const { data } = await axios.get(Endpoint.SURVEY_START_EVENT().url, {
        params: { id }
      })
      return data.data.response
    } catch (error) {
      super.toError(error)
    }
  }
  static async surveyDisclaimer(id: string): Promise<void> {
    try {
      const { data } = await axios.get(Endpoint.SURVEY_DISCLAIMER_EVENT().url, {
        params: { id }
      })
      return data.data.response
    } catch (error) {
      super.toError(error)
    }
  }
  static async onPause(id: string): Promise<void> {
    try {
      const { data } = await axios.get(Endpoint.ON_PAUSE_EVENT().url, {
        params: { id }
      })
      return data.data.response
    } catch (error) {
      super.toError(error)
    }
  }
}

export const startScreenerEvent = EventService.startScreener
export const surveyDisclaimerEvent = EventService.surveyDisclaimer
export const onPauseEvent = EventService.onPause
